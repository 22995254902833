import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import NavBar from "@/presentation/components/navigation/Navigation.vue";
import UserProfile from "@/presentation/components/user-profile/UserProfile.vue";
import DashboardAlert from "@/presentation/components/dashboard-alert/DashboardAlert.vue";
import routePath from "@/commons/RoutePath";
import DIContainer from "@/core/DIContainer";
import UserInteractor from "@/domain/usecases/UserInteractor";
import AuthInteractor from "@/domain/usecases/AuthInteractor";
import CommonService from "@/services/CommonService";
import { useStore } from "vuex";
import UserTutorial from "@/presentation/components/modals/tutorial/user-tutorial/UserTutorial.vue";
import SMSPopup from "@/presentation/components/modals/sms/SMS.vue";
import OTPPopup from "@/presentation/components/modals/otp/Otp.vue";
import Alert from "@/presentation/components/modals/alert/Alert.vue";
import Constant from '@/commons/Constant';
import RoundInteractor from "@/domain/usecases/RoundInteractor";
export default defineComponent({
    name: "UserDashboard",
    components: {
        Breadcrumb: Breadcrumb,
        NavBar: NavBar,
        UserProfile: UserProfile,
        DashboardAlert: DashboardAlert,
        UserTutorial: UserTutorial,
        SMSPopup: SMSPopup,
        OTPPopup: OTPPopup,
        Alert: Alert
    },
    setup: function () {
        var router = useRouter();
        var store = useStore();
        var userInteractor = DIContainer.instance.get(UserInteractor);
        var roundInteractor = DIContainer.instance.get(RoundInteractor);
        var authInteractor = DIContainer.instance.get(AuthInteractor);
        var state = reactive({
            listBreadcrumb: [
                {
                    route: "",
                    name: "ダッシュボード"
                }
            ],
            userProfile: null,
            preload: true,
            accountId: null,
            showTutorial: false,
            showSMSPopup: false,
            showOtpPopup: false,
            showAlertPopup: false,
            alertContent: {
                title: "",
                content: ""
            },
            isTutorialRead: true,
            teamInvitations: null,
            round_name: null,
            period_time: null,
            next_period: null
        });
        function checkTeamInputPeriod() {
            return roundInteractor.getRoundTeamInputPeriod().then(function (response) {
                state.round_name = response.round_name;
                state.period_time = response.period_time;
                state.next_period = response.next_period ? response.next_period : { start_at: "未定", end_at: "未定" };
            });
        }
        function checkSMS() {
            state.isTutorialRead = true;
            authInteractor.smsCheck()
                .then(function (result) {
                if (!result.data) {
                    return;
                }
                if (result.data.result == false) {
                    state.showSMSPopup = true;
                }
            })
                .catch(function (error) {
            });
        }
        function userInitCheck() {
            userInteractor.userInitCheck()
                .then(function (result) {
                if (!result.data) {
                    return;
                }
                if (result.data.is_sms_read == false) {
                    checkSMS();
                    // state.isTutorialRead = result.data.is_tutorial_read;
                }
                else if (result.data.is_tutorial_read == false) {
                    // state.showTutorial = true;
                }
            })
                .catch(function (error) {
            });
        }
        function readTutorial() {
            router.push("/user/profile/" + state.accountId + "/info");
        }
        function readSMS() {
            return userInteractor.userSmsRead().then(function (result) { });
        }
        function userTeaminvitation() {
            userInteractor.userTeamInvitation()
                .then(function (result) {
                if (result.data.length !== 0) {
                    state.teamInvitations = result.data;
                }
                else {
                    state.teamInvitations = null;
                }
            })
                .catch(function (error) {
                state.teamInvitations = null;
            });
        }
        onMounted(function () {
            store.commit("checkReadGuideScreen", Constant.GUIDE_USER_DASHBOARD);
            var user = CommonService.getCookie("user");
            var user_infor = user ? JSON.parse(decodeURIComponent(user)) : null;
            var id = user_infor && user_infor['account_id'] ? user_infor['account_id'] : null;
            userInteractor.getUserProfile(id).then(function (result) {
                state.preload = false;
                if (!result.data) {
                    return;
                }
                state.userProfile = result.data;
            }).catch(function (error) {
                state.preload = false;
            });
            userInitCheck();
            state.accountId = id;
            userTeaminvitation();
            checkTeamInputPeriod();
        });
        function openOTP() {
            state.showOtpPopup = true;
            readSMS();
        }
        function closeSMS() {
            state.showSMSPopup = false;
            readSMS();
            if (!state.isTutorialRead) {
                state.showTutorial = true;
            }
        }
        function closeOTP() {
            state.showOtpPopup = false;
            if (!state.isTutorialRead) {
                state.showTutorial = true;
            }
        }
        function confirmationInvitation(invitation) {
            router.push(routePath.INVITED_TEAM + '?token=' + invitation.invite_token + '&team_id=' + invitation.team_id);
        }
        return {
            state: state,
            routePath: routePath,
            readTutorial: readTutorial,
            closeSMS: closeSMS,
            closeOTP: closeOTP,
            openOTP: openOTP,
            confirmationInvitation: confirmationInvitation
        };
    }
});
