<template>
  <div class="user_dashboard">
    <NavBar />
    <Breadcrumb :breadcrumb="state.listBreadcrumb" />
    <!-- お知らせ -->
    <div v-if="state.next_period" class="user_dashboard__info">
      <div class="information_area">
        <div class="information_area__block">
          <div class="information_area__block__news">
            <span class="label_top">
              お知らせ
            </span>
          </div>
          <div class="information_area__block__contents">
            <div v-if="state.round_name && state.period_time?.start_at && state.period_time?.end_at" class="information_area__block__contents__main__round">
              <div class="information_area__block__contents__main__round__set">
                <span class="information_area__block__contents__main__round__set__text">
                  現在{{ state.round_name }}のチーム情報変更期間です。
                  期間内にチーム情報の登録、編集を行うようにしてください。
                </span><br>
                <span class="time_limit">
                  チーム情報変更期間:{{ state.period_time?.start_at }} 〜 {{ state.period_time?.end_at }}
                </span>
              </div>
            </div>
            <div v-else class="information_area__block__contents__main__round">
              <div class="information_area__block__contents__main__round__set">
                <span class="information_area__block__contents__main__round__set__text">
                現在はチーム情報変更期間外です。
                チーム情報の登録、編集は行えません。
                </span><br>
                <span v-if="state.next_period?.start_at === '未定'" class="time_limit">
                  次回のチーム情報変更期間は未定です。
                </span>
                <span v-else class="time_limit">
                  次回のチーム情報変更期間:{{ state.next_period?.start_at }} 〜 {{ state.next_period?.end_at}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 招待 -->
    <div v-if="state.teamInvitations && state.round_name && state.period_time?.start_at && state.period_time?.end_at" class="user_dashboard__info">
      <div class="information_area">
        <div class="information_area__block">
          <div class="information_area__block__news">
            <span class="label_top">
              　招待　
            </span>
          </div>
          <div class="information_area__block__contents">
            <div v-for="invitation in state.teamInvitations" class="information_area__block__contents__main">
                <div class="information_area__block__contents__main__team_invitation">
                  <span class="information_area__block__contents__main__team_invitation__text">
                    {{invitation.team_name}} から招待が届いています。
                  </span>
                  <span class="time_limit">
                    有効期限：{{invitation.invite_token_expired_at}}
                  </span>
                </div>

                <button class="btn-blue btn-md sweep-to-top confirmation_btn"
                  @click="confirmationInvitation(invitation)">
                  確認する
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="user_dashboard__container container">
      <div class="user_dashboard__container__left left-side">
        <UserProfile :accountId="state.accountId"/>
      </div>
      <div class="user_dashboard__container__right main-area">
        <div class="user_dashboard__container__right__box">
          <div class="user_dashboard__container__right__box__content">
            <div class="user_dashboard__container__right__box__content__tabs">
              <router-link
                class="tab-item"
                :to="{ path: routePath.USER_DASHBOARD_PROJECTS }"
              >
                マッチングプロジェクト
              </router-link>
            </div>
            <router-view />
          </div>
        </div>
      </div>
    </div>
    <UserTutorial v-if="state.showTutorial" @close="state.showTutorial = false" @read="readTutorial"/>

    <SMSPopup
        v-if="state.showSMSPopup == true"
        @openOtp="openOTP"
        @close="closeSMS"
    />
    <OTPPopup
        v-if="state.showOtpPopup == true"
        @close="state.showOtpPopup = false"
    />
    <Alert
      v-if="state.showAlertPopup == true"
      :title="state.alertContent.title"
      :content="state.alertContent.content"
      @close="closePopup"
    />
  </div>
</template>

<script lang="ts" src="@/presentation/views/user/dashboard/Dashboard.ts" scoped>
</script>

<style lang="scss" src="@/presentation/views/user/dashboard/Dashboard.scss" scoped>
</style>
