<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-body">
                        <slot name="body">
                            <div class="profile_activity__edit__container">
                                <div class="profile_activity__edit__container-title">
                                    世界観
                                </div>
                               <div class="profile_activity__edit__container-sub-title">静的に作成予定</div>
                               <div
                                        class="profile_activity__edit__container-button"
                                    >
                                        <button
                                        @click="onSubmit"
                                            :disabled="state.isSubmitting"
                                            :class="{
                                                submitting: state.isSubmitting
                                            }"
                                            type="submit"
                                            class="btn-gray btn-md shadow"
                                        >
                                            <i
                                                v-if="state.isSubmitting"
                                                class="fas fa-circle-notch fa-spin"
                                            ></i>
                                            プロフィールページへ
                                        </button>
                                    </div>
                            </div>

                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/tutorial/user-tutorial/UserTutorial.ts" />
<link type="scss" scoped src="@/presentation/components/modals/tutorial/user-tutorial/UserTutorial.scss" />
