import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import DIContainer from "@/core/DIContainer";
import UserInteractor from "@/domain/usecases/UserInteractor";
export default defineComponent({
    name: "OrganizationTutorialPopup",
    setup: function (props, _a) {
        var emit = _a.emit;
        var store = useStore();
        var router = useRouter();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            isSubmitting: false
        });
        var userInteractor = DIContainer.instance.get(UserInteractor);
        function onSubmit() {
            state.isSubmitting = true;
            return userInteractor
                .readTutorial()
                .then(function (result) {
                state.isSubmitting = false;
                emit("close");
                emit("read");
            }, function (error) {
                state.isSubmitting = false;
            });
        }
        return {
            state: state,
            onSubmit: onSubmit
        };
    }
});
